import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import Swal from 'sweetalert2';
import { browser } from 'protractor';


@Injectable({ providedIn: 'root' })
export class AuthLoginauthenticationService {
     
    private baseUrl = 'https://r2HdVkeSCdtbSzOG.bullybouncer.com';
    // private baseUrl = 'http://localhost:5000';
    private loginUrl = '/api/rest/dashboard/user/login';

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.setCurrentUser(JSON.parse(localStorage.getItem('currentUser')))
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    getCurrentUser(): Observable<User> {
        return this.currentUser;
    }
    setCurrentUser(user) {
        this.currentUserSubject.next(user);
      }
    
    login(username: string, password: string) {
        return this.http.post<any>(`${this.baseUrl}${this.loginUrl}`, { username, password })
            .pipe(map(user => {
                

                // login successful if there's a jwt token in the response
                if (user && user.responseCode=="00") {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }else if(user && user.responseCode=="01"){
                    this.failedAlert(user.responseDescription,"The username or password you entered is incorrect","error")
                    return false;


                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        console.log("logging out");
        window.location.reload();
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
    failedAlert(title,msg,type){
        Swal.fire(
            title,
            msg,
            type
          )
    }
}
